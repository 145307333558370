import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import IframeResizer from "@iframe-resizer/react"
import { IFRAMERESIZER } from "../utils/ug-utils"

const UpdateYourContactInformationPage = ({ data }) => {
  return (
    <Layout>
      {/**** Header and Title ****/}
      <Seo title="Update Your Contact Information" />
      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          <Row className="row-with-vspace site-content">
            <Col md={12} className="content-area">
              {/**** Body content ****/}
              <IframeResizer
                license={IFRAMERESIZER}
                src={process.env.CONTACT_FORM_URL}
                style={{ width: "100%", height: "100vh" }}
                waitForLoad
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default UpdateYourContactInformationPage
